<template>
  <div class="vue-template h-100 bg-dark">
    <div class="row h-100" v-if="!busy">
      <div
        class="col-md-4 h-100 p-0 bg-primary-default d-none d-md-block d-lg-block d-xl-block"
      >
        <div class="h-100 w-100 pt-4 px-5 overflow-hidden">
          <img
            width="120px"
            alt="iRipple"
            src="@/assets/images/iripple-logo-white.png"
          />
          <LandingAnimation class="svg-login-animation" />
        </div>
      </div>
      <div class="col-md-8 h-100 bg-white overflow-y">
        <!-- <img width="100px" class="float-right mx-5 mt-4 d-none d-md-block d-lg-block d-xl-block" alt="iRipple" src="@/assets/images/iripple-logo-primary.png"> -->
        <!-- sample prod push -->
        <img
          width="120px"
          class="float-right mx-5 mt-4 d-block d-md-none d-lg-none d-xl-none"
          src="@/assets/images/iripple-logo-primary.png"
        /><br />
        <div class="p-5">
          <div class="row my-4 login-form">
            <div class="col-md-1">
              &nbsp;
            </div>
            <div class="col-md-10 py-3">
              <div class="d-none d-lg-block d-xl-block pt-5">&nbsp;</div>
              <div
                v-if="isMobile"
                class="d-block d-md-none d-lg-none d-xl-none py-3"
              >
                &nbsp;
              </div>
              <h3 class="text-header-static text-dark">Welcome back!</h3>
              <p class="text-escalated">
                Let's get you back to your account. Login below to continue.
              </p>
              <transition>
                <div class="mt-5" v-if="!forgotPassword">
                  <div class="form-group my-3">
                    <input
                      v-on:keyup.enter="validateForm"
                      v-model="email"
                      type="email"
                      placeholder="Email address"
                      class="form-control text-escalated p-4"
                    />
                  </div>
                  <div class="form-group my-3">
                    <input
                      v-on:keyup.enter="validateForm"
                      v-model="password"
                      :type="showPassword ? `text` : `password`"
                      placeholder="Password"
                      class="form-control text-escalated p-4"
                    />
                    <div
                      class="btn btn-muted btn-lg p-4 btn-search btn-search-end float-right"
                      @mouseover="showPassword = true"
                      @mouseleave="showPassword = false"
                      style="position: relative; margin-top: -77.5px; z-index: 999"
                    >
                      <i
                        class="text-secondary fas"
                        :class="showPassword ? `fa-eye-slash` : `fa-eye`"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div
                        v-on:click="validateForm"
                        class="btn btn-primary bg-shadow btn-block btn-100 p-3 text-header text-white"
                      >
                        <i class="fas fa-unlock"></i>&nbsp;&nbsp;&nbsp;Login
                      </div>
                    </div>
                    <div class="col-lg-6 d-none" v-if="false">
                      <GoogleOAuthComponent
                        @loginWithGoogle="loginWithGoogle($event)"
                      />
                    </div>
                    <div class="col-lg-6 d-none" v-if="false">
                      <BXIAuthComponent @loginWithBXI="loginWithBXI($event)" />
                    </div>
                  </div>
                  <p
                    v-on:click="toggleForgotPassword"
                    class="text-closed cursor-pointer"
                  >
                    Forgot your password?
                  </p>
                </div>
              </transition>
              <div class="col-md-2"></div>
              <transition>
                <div class="mt-5" v-if="forgotPassword">
                  <div class="form-group my-3">
                    <input
                      v-on:keyup.enter="validateEmail"
                      v-model="email"
                      type="email"
                      placeholder="Email address"
                      class="form-control text-escalated p-4"
                    />
                  </div>
                  <div
                    v-on:click="validateEmail"
                    class="btn btn-primary btn-block btn-100 p-3 text-header text-white"
                  >
                    <i class="fas fa-email"></i>&nbsp;&nbsp;Send email
                    confirmation
                  </div>
                  <p
                    v-on:click="toggleForgotPassword"
                    class="text-closed cursor-pointer"
                  >
                    Try logging in now
                  </p>
                </div>
              </transition>
            </div>
            <div class="col-md-1">
              &nbsp;
            </div>
          </div>
        </div>
        <div v-if="isMobile" class="d-block d-md-none d-lg-none d-xl-none py-2">
          &nbsp;
        </div>
        <div class="w-100 text-center text-secondary pb-5">
          iRipple Group of Companies | 2021
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import GoogleOAuthComponent from "../GoogleOAuth/Connect";
import BXIAuthComponent from "../BXIAuth/Connect";
import LandingAnimation from "./LandingAnimation";

export default {
  components: {
    GoogleOAuthComponent,
    BXIAuthComponent,
    LandingAnimation,
  },
  data() {
    return {
      email: "",
      password: "",
      errors: [],
      forgotPassword: false,
      showPassword: false,
      busy: true,
    };
  },
  computed: {
    isMobile: function() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      )
        return true;
      else return false;
    },
  },
  methods: {
    loader: function(val) {
      this.$emit("loader", val);
    },
    toggleForgotPassword: function() {
      this.forgotPassword = !this.forgotPassword;
    },
    validateEmail: function() {
      this.errors = [];
      if (this.email.trim() === "") this.errors.push("Email cannot be empty");
      if (this.errors.length > 0) {
        this.errors.reverse().forEach((error) => {
          this.$alertify({
            group: "notification",
            title: "User login",
            type: "warn",
            text: error,
          });
        });
        return false;
      } else {
        this.submitEmailConfirmation();
      }
    },
    validateForm: function() {
      this.errors = [];
      this.email = this.email.trim();
      this.password = this.password.trim();
      if (this.password === "") this.errors.push("Password cannot be empty");
      if (this.email === "") this.errors.push("Email cannot be empty");
      if (!this.$validateEmail(this.email))
        this.errors.push("Invalid email address");
      if (this.errors.length > 0) {
        this.errors.reverse().forEach((error) => {
          this.$alertify({
            group: "notification",
            title: "User login",
            type: "warn",
            text: error,
          });
        });
        return false;
      } else {
        this.submitLogin();
      }
    },
    async submitLogin() {
      this.busy = true;
      this.loader(true);
      try {
        const data = {
          email: this.email,
          password: this.password,
          version: 3,
        };
        const response = await this.$http.post(
          `${this.$apiEndpoint}/user/login`,
          data
        );
        if (
          response &&
          response.data &&
          response.data.user &&
          response.data.user._id
        ) {
          await this.$sleep(1500);
          this.initializeLogin(response);
          await this.$sleep(1500);
          window.location.href = "/";
        }
      } catch (error) {
        this.busy = false;
        this.$alertify({
          group: "notification",
          title: "Login failed",
          type: "error",
          text:
            !error || !error.response
              ? "Cannot connect to the backend"
              : error &&
                error.response &&
                error.response.data &&
                error.response.data.error
              ? error.response.data.error
              : "Email or Password not found",
        });
      }
      this.loader(false);
    },
    loginWithGoogle: async function(data) {
      this.busy = true;
      this.loader(true);
      try {
        const response = await this.$http.post(
          `${this.$apiEndpoint}/v1/integrations/login`,
          data
        );
        if (
          response &&
          response.data &&
          response.data.user &&
          response.data.user._id
        ) {
          await this.$sleep(1500);
          this.initializeLogin(response);
          await this.$sleep(1500);
          window.location.href = "/";
        }
      } catch (error) {
        this.busy = false;
        this.$alertify({
          group: "notification",
          title: "Login failed",
          type: "error",
          text:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error
              ? error.response.data.error
              : "Email or Password not found",
        });
      }
      this.loader(false);
    },
    loginWithBXI: async function(data) {
      await this.$sleep(1500);
      this.initializeLogin(data);
      await this.$sleep(1500);
      window.location.href = "/";
    },
    initializeLogin: function(response) {
      let userData = {
        user: response.data.user,
        authToken: response.data.token,
        gitlabToken: response.data.gitlabPersonalToken,
        login_timestamp: new Date(),
      };
      localStorage.setObject("userData", userData);
      localStorage.setItem("newLogin", true);
      localStorage.removeItem("ticketFilters");
      localStorage.removeItem("sessionAlertFlag");
      Vue.prototype.$userData = userData;
      this.$alertify({
        group: "notification",
        title: "Login successful",
        type: "success",
        text: "Welcome back " + response.data.user.name,
      });
      return true;
    },
    async submitEmailConfirmation() {
      this.busy = true;
      this.loader(true);
      try {
        const data = {
          email: this.email,
        };
        const response = await this.$http.post(
          `${this.$apiEndpoint}/v1/user/reset`,
          data
        );
        this.email = "";
        this.$alertify({
          group: "notification",
          title: "Password reset",
          type:
            response && response.data && response.data.isValid
              ? "success"
              : "error",
          text:
            response && response.data && response.data.message
              ? response.data.message
              : "Please check your email for the instructions.",
        });
      } catch (error) {
        this.busy = false;
        console.log(error);
      }
      this.busy = false;
      this.loader(false);
    },
  },
  mounted: function() {
    this.busy = false;
    let userData = localStorage.getObject("userData");
    Vue.prototype.$userData = userData;
    if (userData && userData.authToken) {
      this.$router.push("/");
    }
  },
};
</script>
