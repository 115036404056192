<template>
	<div>
		<LottieAnimation
      ref="anim"
      :animationData="require('@/assets/json/landing_svg.json')"
      background="transparent"
      :loop="true"
      :autoPlay="true"
      :speed="1"
    />
	</div>
</template>
<script>
  import Vue from 'vue'
	import LottieAnimation from 'lottie-web-vue'
	Vue.use(LottieAnimation)

  export default {
    components: {
      LottieAnimation
    }
  }
</script>
