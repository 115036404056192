<template>
  <div @click="openLoginForm" class="btn btn-white bg-shadow btn-block btn-100 p-3 text-header text-dark" v-tooltip="`For BXI users only`">
  <img src="https://bxi-upload-bucket.s3.ap-southeast-1.amazonaws.com/iripple-images/accounts/irippledemo/1657239408099_070822081646-62-logo2.png" style="height: 30px; margin: -2.5px; margin-top: -10px;"/>Continue with BXI
  </div>
</template>

<script>
  export default {
    name: 'Connect',
    data(){
      return{
        interval: false
      }
    },
    methods: {
      openLoginForm: function() {
        this.clearInterval()
        var features = 'height=650,width=500,top=100,left=100,toolbar=0,Location=0,Directories=0,Status=0,menubar=0,Scrollbars=0,Resizable=1';
        window.open("/bxi/login", "Login with BXI", features)
        this.interval = setInterval(() => {
          const response = localStorage.getItem('manual_response')
          const window_close = localStorage.getItem('manual_close')
          if(response){
            this.$emit('loginWithBXI', JSON.parse(response))
            this.clearInterval()
          }
          else if(window_close == "1")
            this.clearInterval()
        }, 1000)
      },
      clearInterval: function(){
        localStorage.removeItem('manual_response')
        localStorage.removeItem('manual_close')
        clearInterval(this.interval)
      }
    }
  }
</script>
